import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a3b5869"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "side-menu" }
const _hoisted_2 = { class: "ml-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menu) => {
        return (_openBlock(), _createElementBlock("li", {
          class: _normalizeClass(["menu-item hover-animation", {
          'text-primary-100': _ctx.currentPath.indexOf(menu.path) > -1,
        }]),
          key: menu.name
        }, [
          _createVNode(_component_router_link, {
            class: "flex items-center",
            to: { name: menu.name }
          }, {
            default: _withCtx(() => [
              _createElementVNode("i", {
                class: _normalizeClass(`icon-${menu.meta?.icon} font-normal`),
                style: {"font-size":"1.5rem"}
              }, null, 2),
              _createElementVNode("span", _hoisted_2, _toDisplayString(menu.meta?.title), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ], 2))
      }), 128))
    ])
  ]))
}