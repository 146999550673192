import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46aada73"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app" }
const _hoisted_2 = { class: "side-menu" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "main-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_side_menu = _resolveComponent("side-menu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "h-11 mb-14 ml-4 cursor-pointer",
        src: _ctx.logo,
        alt: "",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toHomePage && _ctx.toHomePage(...args)))
      }, null, 8, _hoisted_3),
      _createVNode(_component_side_menu)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_router_view)
    ])
  ]))
}