import { createStore } from "vuex";

export default createStore({
  state: {
    currentPath: "",
  },
  mutations: {
    CURRENT_PATH(state, payload) {
      state.currentPath = payload;
    },
  },
  getters: {
    currentPath(state) {
      return state.currentPath;
    },
  },
  actions: {},
  modules: {},
});
