
import { defineComponent } from "vue";
import SideMenu from "@/components/SideMenu.vue";
import logo from "@/assets/webps/logoWithText.webp";
import { useRouter } from "vue-router";

export default defineComponent({
  components: { SideMenu },
  setup() {
    const router = useRouter();

    const toHomePage = () => {
      router.push({ name: "tracking" });
    };

    return {
      toHomePage,
      logo,
    };
  },
});
