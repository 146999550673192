
import { defineComponent, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const menus = useRouter().options.routes.filter(
      (item) => item.meta?.isMenu
    );
    const store = useStore();
    const currentPath = computed(() => {
      return store.getters.currentPath;
    });
    return {
      menus,
      currentPath,
    };
  },
});
