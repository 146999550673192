/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { createApp } from "vue";
import "./assets/tailwind.css";
import "./assets/rtl_margins.css";
import "./assets/rtl_paddings.css";
import "./assets/rtl_text.css";
import "./assets/icon-font/icon.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import locales from "./locales";
import lazyImg from "@/directives/imgLazyLoad";

export default createApp(App)
  .use(locales)
  .use(lazyImg)
  .use(store)
  .use(router).mount("#app");
