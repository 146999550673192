import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/tracking",
  },
  {
    path: "/tracking",
    name: "tracking",
    meta: {
      title: "Tracking",
      isMenu: true,
      icon: "tracking",
    },
    component: () => import("@/views/Tracking.vue"),
  },
  {
    path: "/rank",
    name: "rank",
    meta: {
      title: "Rank",
      isMenu: true,
      icon: "rank",
    },
    component: () => import("@/views/Rank.vue"),
  },
  {
    path: "/next-blue-chip",
    name: "nextBlueChip",
    meta: {
      title: "NextBlueChip",
      isMenu: true,
      icon: "trend_upward",
    },
    component: () => import("@/views/NextBlueChip.vue"),
  },
  {
    path: "/item-detail",
    name: "itemDetail",
    meta: {
      title: "ItemDetail",
    },
    component: () => import("@/views/ItemDetail.vue"),
  },
  {
    path: "/collection-detail/:contract",
    name: "CollectionDetail",
    meta: {
      title: "CollectionDetail",
    },
    component: () => import("@/views/CollectionDetail.vue"),
  },
  {
    path: "/wallet-detail/:addr?",
    name: "WalletDetail",
    meta: {
      title: "WalletDetail",
    },
    component: () => import("@/views/WalletDetail.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to) => {
  store.commit("CURRENT_PATH", to.fullPath);
});

export default router;
